import * as React from 'react';
import PersephoneContext from './PersephoneContext';
import {
  doQuoteRequest,
  doCatalogRequest,
  Catalog,
  doMaterialInventoryRequest,
  MaterialInventory,
} from 'api/persephone/persephone';
import { Status } from 'libs/utils/api/types';

interface Props {
  token: string;
  endpoint: string;
  options: string[];
  catalog?: Catalog;
  materialInventory?: MaterialInventory[];
  admin?: boolean;
  children: React.ReactNode;
}

const PersephoneContextContainer: React.FunctionComponent<Props> = ({ token, endpoint, admin, ...props }) => {
  const [catalog, setCatalog] = React.useState<Catalog>(props.catalog ? props.catalog : null);
  const [materialInventory, setMaterialInventory] = React.useState<MaterialInventory[]>(
    props.materialInventory ? props.materialInventory : null,
  );
  const [available, setAvailable] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!catalog) {
      doCatalogRequest(endpoint, token).then(res => {
        if (res.status === Status.Ok) {
          setCatalog(res.payload);
        } else {
          setAvailable(false);
        }
      });
    }
    if (!materialInventory && admin) {
      doMaterialInventoryRequest(endpoint, token).then(res => {
        if (res.status === Status.Ok) {
          setMaterialInventory(res.payload);
        }
      });
    }
  }, []);

  const contextValue = React.useMemo(
    () => ({
      doQuoteRequest: (data, productionDays, options = props.options) =>
        doQuoteRequest(endpoint, token, data, productionDays, options),
      catalog,
      materialInventory,
      available,
    }),
    [available, catalog, endpoint, materialInventory, props.options, token],
  );

  return <PersephoneContext.Provider value={contextValue}>{props.children}</PersephoneContext.Provider>;
};

export default PersephoneContextContainer;
