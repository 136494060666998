// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import { loadableReady } from '@loadable/component';

import AppContainer from './AppContainer';

import { ReactOnRailsContext } from 'contexts/AppContext/types';
import { AppProps } from './app';
import { hydrateRoot } from 'react-dom/client';

const App = (props: AppProps, railsContext: ReactOnRailsContext, domNodeId: string) => {
  loadableReady(() => {
    const root = document.getElementById(domNodeId);
    hydrateRoot(root, <AppContainer {...props} railsContext={railsContext} />);
  });
};

export default App;
